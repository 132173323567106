import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';



// Actions
import { toggleSidebar } from '../../redux/actions/ui';



// Components
import Icon from '../Icon';
// import NestedLinkButton from './NestedSideBarLink';
import SideBarLink from './SideBarLink';



// Custom hooks
import { useSidebar } from '../../hooks/useSidebar';
import { useWindowDimensions } from '../../hooks/useDimensions';
import NestedLinkButton from './NestedSideBarLink';

// TODO: esto es una prueba

const CONSTANT = {

  filteredNestedButtons: {
    productos: [],
    generales: [],
    proveedores: [],
    erp: [],
    template: [],
    garantias: [],
    devoluciones: [],
    logistica: [],
    autoservicio: []
  },

  nestedButtons: {
    productos: [
      { btnText: 'Listado', icon: 'Table', url: '', permiso: 'productos' },
      { btnText: 'Imágenes', icon: 'Image', url: '/imagenes', permiso: 'productos' },
      { btnText: 'Puntos de entrega', icon: 'Map', url: '/puntos-entrega', permiso: 'puntos-entrega' },
      { btnText: 'Métodos de pago', icon: 'CreditCard', url: '/metodos-pago', permiso: 'metodos-pago' },
      { btnText: 'Adicionales', icon: 'Tag', url: '/adicionales', permiso: 'adicionales' },
      { btnText: 'Ofertas', icon: 'DollarSign', url: '/ofertas', permiso: 'ofertas' },
      { btnText: 'Etiquetas', icon: 'Tag', url: '/etiquetas', permiso: 'etiquetas' },
      { btnText: 'Categorías', icon: 'Table', url: '/categorias', permiso: 'categorias' },
      { btnText: 'Tipos Elementos', icon: 'Table', url: '/tipos-elementos', permiso: 'tipos-elementos' },
      { btnText: 'Claves/Atributos', icon: 'Table', url: '/claves-atributos', permiso: 'atributos' },
      { btnText: 'Valores/Atributos', icon: 'Table', url: '/valores-atributos', permiso: 'atributos' },
    ],
    generales: [
      { btnText: 'Distritos', icon: 'Map', url: '/distritos', permiso: 'distritos' },
      { btnText: 'Ciudades', icon: 'Map', url: '/ciudades', permiso: 'ciudades' },
      { btnText: 'Países', icon: 'Map', url: '/paises', permiso: 'paises' },
      { btnText: 'Comunas', icon: 'Map', url: '/comunas', permiso: 'comunas' },
      { btnText: 'Regiones', icon: 'Map', url: '/regiones', permiso: 'regiones' },
      { btnText: 'Tipos de destino', icon: 'Map', url: '/tipos-destinos', permiso: 'tipos-destino' },
      { btnText: 'Formas de pago', icon: 'CreditCard', url: '/formas-pago', permiso: 'formas-pago' },
      { btnText: 'Tipos de atención', icon: 'User', url: '/tipos-atencion', permiso: 'tipos-atencion' },
      { btnText: 'Métodos de entrega', icon: 'Truck', url: '/metodos-entrega', permiso: 'metodos-entrega' },
      { btnText: 'Metadatos', icon: 'Tag', url: '/metadatos', permiso: 'metadatos' },
      { btnText: 'Estatus pedido', icon: 'Table', url: '/estatus-pedido', permiso: 'estatus-pedido' },
      { btnText: 'Tipos de facturación', icon: 'Table', url: '/tipos-facturacion', permiso: 'tipos-facturacion' },
      { btnText: 'Criterios de comparación', icon: 'Table', url: '/criterios-comparacion', permiso: 'criterios-comparacion' },
      { btnText: 'Días', icon: 'Table', url: '/dias', permiso: 'dias' },
      { btnText: 'Unidades', icon: 'Table', url: '/unidades', permiso: 'unidades' },
      { btnText: 'Divisas', icon: 'DollarSign', url: '/divisas', permiso: 'divisas' },
      { btnText: 'Parámetros Recargo', icon: 'DollarSign', url: '/parametros-recargos', permiso: 'puntos-entrega' },
    ],
    proveedores: [
      { btnText: 'Listado', icon: 'Box', url: '', permiso: 'proveedores' },
      { btnText: 'Productos', icon: 'Truck', url: '/productos', permiso: 'proveedores' },
      { btnText: 'Asignar categorías', icon: 'Tag', url: '/categorias/create', permiso: 'proveedores' },
      { btnText: 'Productos propios', icon: 'User', url: '/productos-propios-proveedor', permiso: 'proveedores' },
    ],
    erp: [
      { btnText: 'Notas de venta', icon: 'Table', url: '/notas-ventas', permiso: 'notas-venta' },
      // { btnText: 'Planificación', icon: 'Calendar', url: '/planificacion', permiso: 'planificar' },
      { btnText: 'Ordenes compra', icon: 'Table', url: '/ordenes-compra', permiso: 'ordenes-compra' },
      { btnText: 'Pagos', icon: 'Table', url: '/pagos', permisos: 'notas-venta' },
    ],
    garantias: [
      { btnText: 'Listado', icon: 'Table', url: '', permiso: 'pedidos' },
      { btnText: 'Respuestas', icon: 'Check', url: '/respuestas', permiso: 'pedidos' },
    ],
    logistica: [
      { btnText: 'Reposición de stock', icon: 'FilePlus', url: '/reportes/reposicion-stock', permiso: 'notas-venta' },
      { btnText: 'No reposición de stock', icon: 'FileMinus', url: '/reportes/no-reposicion-stock', permiso: 'notas-venta' },
      // { btnText: 'Líneas despacho', icon: 'List', url: '/lineas-despacho/validas', permiso: 'notas-venta' },
      // { btnText: 'Líneas agendamiento', icon: 'List', url: '/lineas-agendamiento/validas', permiso: 'notas-venta' },
      // { btnText: 'Replanificación', icon: 'List', url: '/replanificacion', permiso: 'notas-venta' },
      // { btnText: 'Motivos - Replanificación', icon: 'CheckCircle', url: '/replanificacion-motivos', permiso: 'notas-venta' },
    ],
    autoservicio: [
      { btnText: 'Datos', icon: 'Table', url: '/graficas', permiso: 'autoservicio' },
      { btnText: 'Usuarios sucursales', icon: 'User', url: '/usuarios-sucursales', permiso: 'autoservicio' },
      { btnText: 'Sucursales', icon: 'Home', url: '/sucursales', permiso: 'autoservicio' },
    ],
    devoluciones: [
      { btnText: 'Listado', icon: 'Table', url: '', permiso: 'devoluciones' },
      { btnText: 'Motivos', icon: 'CheckCircle', url: '/motivos', permiso: 'devoluciones' },
      { btnText: 'Bancos', icon: 'Briefcase', url: '/bancos', permiso: 'devoluciones' },
      { btnText: 'Reporte general', icon: 'Activity', url: '/reportes/general', permiso: 'devoluciones' },
      { btnText: 'Reporte operativo', icon: 'Activity', url: '/reportes/operativo', permiso: 'devoluciones' },
      { btnText: 'Reporte parcial', icon: 'Activity', url: '/reportes/parcial/vendedores', permiso: 'devoluciones' },
      // { btnText: 'Estatus', icon: 'Activity', url: '/estatus', permiso: 'garantias' }, 
    ],
    template: [
      { btnText: 'Cinta informativa', icon: 'Table', url: '/cinta-informativa', permiso: 'cinta-informativa' },
      { btnText: 'FAQ', icon: 'Table', url: '/faq', permiso: 'faq' },
      { btnText: 'FAQ (Categorías)', icon: 'Table', url: '/faq-categorias', permiso: 'faq' },
      { btnText: 'FAQ (Subcategorías)', icon: 'Table', url: '/faq-subcategorias', permiso: 'faq' },
      { btnText: 'Pasos compra', icon: 'Table', url: '/pasos-compra', permiso: 'pasos-compra' },
      { btnText: 'Componentes', icon: 'Table', url: '/componentes', permiso: 'componentes' },
      { btnText: 'Banners', icon: 'Table', url: '/banners', permiso: 'banners' },
      { btnText: 'Menús', icon: 'Table', url: '/menus', permiso: 'menus' },
      { btnText: 'Factores diferenciadores', icon: 'Table', url: '/factores-diferenciadores', permiso: 'factores-diferenciadores' },
      { btnText: 'Términos y condiciones', icon: 'Table', url: '/terminos-condiciones', permiso: 'terminos-condiciones' },
    ]
  }
}


const SideBar = () => {
  const dispatch = useDispatch();
  const { sidebar } = useSelector(state => state.ui);
  const [filteredNestedButtons, setFilteredNestedButtons] = useState(CONSTANT.filteredNestedButtons)
  const [hovered, setHovered] = useState(false);
  const { width } = useWindowDimensions();
  const { user } = useSelector(state => state.auth)


  useEffect(() => {
    if (!user?.permisos) return
    const bypassPermissions = ['autoservicio']

    let userPermissionList = Object.keys(user?.permisos)?.map((str, i, arr) => {
      const target = str.split('.')[0]
      return (!arr.find(permiso => target === permiso) && str.includes('listar')) ? target : null
    })
      .filter(el => el !== null)
      .concat(bypassPermissions)

    Object.entries(CONSTANT.nestedButtons).forEach(([key, arr]) => {
      arr.forEach(({ permiso }, i) => {
        const isListed = filteredNestedButtons[key]?.find((el) => el.permiso === permiso)
        const hasPermission = userPermissionList?.find(el => el === permiso)

        if (hasPermission && !isListed) {
          setFilteredNestedButtons(botones => ({
            ...botones,
            [key]: [...botones[key], arr[i]]
          }))
        }
      })
    })
  }, [user, filteredNestedButtons])


  useSidebar();


  const handleCollapsedMenu = () => dispatch(toggleSidebar(!sidebar));


  const handleHovered = () => !sidebar && setHovered(true);


  const handleOnMouseLeave = () => !sidebar && setHovered(false);


  const handleClassName = () => {
    if (width <= 768) {
      return 'main-menu menu-fixed menu-light menu-accordion menu-shadow expanded';
    }

    if (hovered || sidebar) {
      return 'main-menu menu-fixed menu-light menu-accordion menu-shadow expanded';
    } else {
      return 'main-menu menu-fixed menu-light menu-accordion menu-shadow';
    }
  }


  if (!Object.entries(user).length) return (<></>)


  return (
    <div
      className={handleClassName()}
      data-scroll-to-active="true"
      onMouseEnter={handleHovered}
      onMouseLeave={handleOnMouseLeave}
    >
      <div
        className={hovered
          ? "navbar-header expanded"
          : "navbar-header"
        }
      >
        <ul className="nav navbar-nav flex-row justify-content-between">
          <li className='z-10000 position-absolute' style={{ fontSize: '.6em', right: 'calc(0px + 10px)' }}>v.{process.env.REACT_APP_VERSION}{process.env.REACT_APP_ENV === "prod" ? 'P' : 'D'}</li>

          <li className="nav-item logo-item">
            <Link className="" to={'/'}>
              <img src={process.env.PUBLIC_URL + "/logo_oscuro.svg"} alt="brand-logo" />
            </Link>
          </li>

          <li className="nav-item nav-toggle">
            <div className="nav-link modern-nav-toggle pe-0" onClick={() => handleCollapsedMenu()}>
              <Icon
                icon='X'
                className="d-block d-xl-none text-primary toggle-icon font-medium-4"
              />

              {sidebar
                ? <Icon
                  icon='Disc'
                  className='d-none d-xl-block collapse-toggle-icon font-medium-4 text-primary'
                />

                : <Icon
                  icon='Circle'
                  className='d-none d-xl-block collapse-toggle-icon font-medium-4 text-primary'
                />
              }
            </div>
          </li>

        </ul>
      </div>

      <div className="shadow-bottom"></div>

      <div className="main-menu-content overflow-auto custom-scrollbar pb-2">
        <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
          <SideBarLink text='Home' icon='Home' url='/home' />

          {user.permisos['usuarios.listar'] &&
            <SideBarLink text='Usuarios' icon='Users' url='/usuarios?tipo_usuario=admin' />
          }

          {user.permisos['empresas.listar'] &&
            <SideBarLink text='Empresas' icon='Briefcase' url='/empresas' />
          }

          {/* {user.permisos['productos.listar'] &&
            <SideBarLink text='Servicios' icon='Tool' url='/servicios' />
          } */}

          {user.permisos['productos.listar'] && filteredNestedButtons.productos.length
            ? <NestedLinkButton
              text='Productos'
              path='/productos'
              icon='Package'
              buttons={filteredNestedButtons.productos}
            />

            : <></>
          }

          {user?.permisos['devoluciones.listar'] && filteredNestedButtons?.devoluciones?.length
            ? <NestedLinkButton
              text='Devoluciones'
              path='/devoluciones'
              icon='FileMinus'
              buttons={filteredNestedButtons.devoluciones}
            />

            : <></>
          }

          {filteredNestedButtons.autoservicio.length
            ? <NestedLinkButton
              text='Autoservicio'
              path='/autoservicio'
              icon='Check'
              buttons={filteredNestedButtons.autoservicio}
            />

            : <></>
          }

          {filteredNestedButtons.logistica.length
            ? <NestedLinkButton
              text='Logística'
              path='/logistica'
              icon='GitPullRequest'
              buttons={filteredNestedButtons.logistica}
            />

            : <></>
          }

          {filteredNestedButtons.erp.length
            ? <NestedLinkButton
              text='ERP'
              path='/erp'
              icon='Truck'
              buttons={filteredNestedButtons.erp}
            />

            : <></>
          }

          {user.permisos['pedidos.listar'] &&
            <SideBarLink text='Pedidos' icon='List' url='/pedidos' />
          }

          {user.permisos['cotizaciones.listar'] &&
            <SideBarLink text='Cotizaciones' icon='List' url='/cotizaciones' />
          }

          {user.permisos['pedidos.cotizaciones'] &&
            <SideBarLink text='Reporte Pedidos/Cotizaciones' icon='Activity' url='/reporte-pedidos-cotizaciones' />
          }

          {user.permisos['garantias.listar'] && filteredNestedButtons.garantias.length
            ? <NestedLinkButton
              text='Garantías'
              path='/garantias'
              icon='Table'
              buttons={filteredNestedButtons.garantias}
            />

            : <></>
          }

          {user.permisos['agendamientos.listar'] &&
            <SideBarLink text='Agendamientos' icon='Calendar' url='/agendamientos' />
          }

          {user.permisos['puntos-entrega.listar'] &&
            <SideBarLink text='Puntos de entrega' icon='Map' url='/puntos-entrega' />
          }

          <SideBarLink text='Cuentas caja' icon='Table' url='/cuentas-caja' />

          <SideBarLink text='Bodegas' icon='Box' url='/bodegas' />

          {user.permisos['bodegas.listar'] &&
            <SideBarLink text='Bodegas' icon='Truck' url='/puntos-entrega/bodegas' />
          }

          {user.permisos['metodos-pago.listar'] &&
            <SideBarLink text='Métodos de pago' icon='CreditCard' url='/metodos-pago' />
          }

          {user.permisos['ofertas.listar'] &&
            <SideBarLink text='Ofertas' icon='CreditCard' url='/ofertas' />
          }

          {user.permisos['etiquetas.listar'] &&
            <SideBarLink text='Etiquetas' icon='Tag' url='/etiquetas' />
          }

          {user.permisos['adicionales.listar'] &&
            <SideBarLink text='Adicionales' icon='Tag' url='/adicionales' />
          }

          {user.permisos['historial.listar'] &&
            <SideBarLink text='Historial' icon='List' url='/historial-cambios' />
          }

          {user.roles.find(({ guard_name }) => guard_name === 'admin') &&
            <SideBarLink text='Claves' icon='Settings' url='/claves' />
          }

          {filteredNestedButtons.proveedores.length
            ? <NestedLinkButton
              text='Generales'
              path='/generales'
              icon='List'
              buttons={filteredNestedButtons.generales}
            />

            : <></>
          }

          <SideBarLink text='Reporte Ventas/Día' icon='Table' url='/reporte-ventas' />

          {user.permisos['eventos.listar'] &&
            <SideBarLink text='Eventos' icon='Sun' url='/eventos' />
          }

          {user.permisos['busquedas-clientes.listar'] &&
            <SideBarLink text='Búsqueda clientes' icon='Search' url='/busqueda-clientes' />
          }

          {user.permisos['codigos-descuento.listar'] &&
            <SideBarLink text='Códigos descuentos' icon='Tag' url='/codigos-descuento' />
          }

          {user.permisos['roles.listar'] &&
            <SideBarLink text='Roles' icon='Tag' url='/roles' />
          }

          {/* <SideBarLink text='Permisos' icon='Tag' url='/permisos' /> */}

          {filteredNestedButtons.proveedores.length
            ? <NestedLinkButton
              text='Proveedores'
              path='/proveedores'
              icon='Truck'
              buttons={filteredNestedButtons.proveedores}
            />

            : <></>
          }


          {filteredNestedButtons.template.length
            ? <NestedLinkButton
              text='Template'
              path='/template'
              icon='Truck'
              buttons={filteredNestedButtons.template}
            />

            : <></>
          }

        </ul>
      </div>

    </div>
  );
};

export default SideBar;