import React, { useEffect, useState } from 'react'
import AccordionTable from '../../../layout/AccordionTable'
import CustomLoader from '../../CustomLoader'
import FormInput from '../../FormInput'
import { PrecioFormatter } from '../../../helpers/precioFormatter'
import Table from '../../Table'

const PrecioRaw = (v) => new PrecioFormatter(v).producto(true)

const CONSTANT = {
  pathUrl: '/reporte-ventas',
  permissionsPath: 'reporte-ventas',

  datatableHeadInputState: {
    sku: {
      descripcion: '',
      sku: ''
    }
  }
}

export const ReportePorSkuTable = ({ data = [] }) => {
  const [datatableHeadInput, setDatatableHeadInput] = useState(CONSTANT.datatableHeadInputState)

  // FUNCION MANEJADORA DE LOS INPUTS DEL HEADER
  const handleDatatableHeaderInputs = (e) => {
    const { name, value } = e.target
    const [target, key] = name.split('-')

    setDatatableHeadInput({
      ...datatableHeadInput,
      [target]: {
        ...datatableHeadInput[target],
        [key]: value?.toLowerCase()
      },
    })
  }

  return (
    <AccordionTable
      title={`Reporte por SKU (${data?.length ?? 0} resultados)`}
      classes={'mb-2'}
    >
      {!data && <CustomLoader blocking={'partial'} />}

      <Table pb={false} style={{ maxHeight: '1000px' }}>
        <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
          <tr role="row">
            <th>
              <FormInput
                labelText=''
                placeholder="SKU"
                name='sku-sku'
                value={datatableHeadInput.sku.sku}
                handleValue={handleDatatableHeaderInputs}
                onKeyDown={handleDatatableHeaderInputs}
                size="col-12"
                sizeDesk="col-md-12"
                margin="mb-0"
              />
            </th>

            <th>
              <FormInput
                labelText=''
                placeholder="Descripción"
                name='sku-descripcion'
                value={datatableHeadInput.sku.descripcion}
                handleValue={handleDatatableHeaderInputs}
                onKeyDown={handleDatatableHeaderInputs}
                size="col-12"
                sizeDesk="col-md-12"
                margin="mb-0"
              />
            </th>
            <th>Cantidad</th>
            <th>Precio Unit. prom.</th>
            <th>Precio total</th>
          </tr>
        </thead>
        <tbody>
          {data?.length
            ? data
              ?.filter(p =>
                String(p.sku).includes(datatableHeadInput.sku.sku)
                &&
                (String(p.producto).toLowerCase().includes(datatableHeadInput.sku.descripcion)
                  || p.parametros_busqueda.toLowerCase().includes(datatableHeadInput.sku.descripcion))
              )
              ?.map((data, i) => {
                return (
                  <tr className="odd" key={`rsm-sku-${data.sku}-${data.i}`}>
                    <td>{data?.sku ?? 'S/P'}</td>
                    <td>{data?.producto ?? "N/A"}</td>
                    <td>{data?.cantidad ?? "N/A"}</td>
                    <td><b>{PrecioRaw(data?.precio_unitario_promedio)}</b></td>
                    <td><b>{PrecioRaw(data?.precio_total)}</b></td>
                  </tr>
                )
              })
            : <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
          }
        </tbody>
      </Table>
    </AccordionTable>
  )
}