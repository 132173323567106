import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";

// Importaciones de los reducers
import {
   productosReducer,
   logisticaLineasDespachoReducer,
   empresasReducer,
   puntosEntregaReducer,
   uiReducer,
   etiquetasReducer,
   pedidosReducer,
   adicionalesReducer,
   metodosPagoReducer,
   ofertasReducer,
   atributosValoresRelacionesReducer,
   agendamientosReducer,
   notasVentasReducer,
   ordenesCompraReducer,
   toasterReducer,
   paisesReducer,
   regionesReducer,
   comunasReducer,
   tiposElementosReducer,
   categoriasReducer,
   cintaInformativaReducer,
   faqSubcategoriasReducer,
   faqReducer,
   pasosCompraReducer,
   componentesReducer,
   bannersReducer,
   menusReducer,
   factoresDiferenciadoresReducer,
   terminosCondicionesReducer,
   unidadesReducer,
   proveedoresReducer,
   clavesAtributoReducer,
   divisasReducer,
   valoresAtributoReducer,
   tiposDestinoReducer,
   diasReducer,
   codigosDescuentoReducer,
   criteriosComparacionReducer,
   tiposFacturacionReducer,
   estatusPedidoReducer,
   listedResultsReducer,
   metadatosReducer,
   metodosEntregaReducer,
   rolesReducer,
   productosEtiquetasReducer,
   historialBusquedaClienteReducer,
   tiposAtencionReducer,
   formasPagoReducer,
   productosOfertasReducer,
   modalProductosFiltrosReducer,
   ciudadesReducer,
   agendamientosPuntoEntregaReducer,
   productosAdicionalesReducer,
   authReducer,
   distritosReducer,
   usuariosReducer,
   productosPuntosEntregaReducer,
   productosMetodosPagoReducer,
   eventosReducer,
   busquedaClientesReducer,
   bodegasPuntoEntregaReducer,
   productosProveedoresReducer,
   clavesReducer,
   historialCambiosReducer,
   productosPropiosProveedorReducer,
   reporteVentasReducer,
   garantiasReducer,
   garantiasRespuestasReducer,
   parametrosRecargosReducer,
   autogestionReducer,
   serviciosReducer,
   productosImagenesReducer,
   devolucionesReducer,
   devolucionesMotivosReducer,
   devolucionesEstatusReducer,
   devolucionesBancosReducer,
   cuentasCajaReducer,
   bodegasReducer,
   logisticaReportesReducer,
   logisticaReplanificacionReducer,
   logisticaReplanificacionMotivosReducer,
   devolucionesReportesReducer,
   autoservicioSucursalesReducer,
   autoservicioUsuariosSucursalesReducer,
   reportePedidosCotizacionesReducer
} from "../reducers";
import { faqCategoriasReducer } from "../reducers/faqCategorias";
import { cotizacionesReducer } from "../reducers/cotizaciones";
import { generarPedidoReducer } from "../reducers/generarPedido";
import { generarCotizacionReducer } from "../reducers/generarCotizacion";
import { logisticaLineasAgendamientosReducer } from "../reducers/logisticaLineasAgendamientos";

// Main middlewares
const middlewares = [thunk];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

// Main reducer
const reducers = combineReducers({
   // Lista de reducers
   logisticaReplanificacionMotivos: logisticaReplanificacionMotivosReducer,
   logisticaReplanificacion: logisticaReplanificacionReducer,
   logisticaLineasAgendamientos: logisticaLineasAgendamientosReducer,
   logisticaLineasDespacho: logisticaLineasDespachoReducer,
   logisticaReportes: logisticaReportesReducer,
   autoservicioUsuariosSucursales: autoservicioUsuariosSucursalesReducer,
   autoservicioSucursales: autoservicioSucursalesReducer,
   ui: uiReducer,
   bodegas: bodegasReducer,
   cuentasCaja: cuentasCajaReducer,
   devoluciones: devolucionesReducer,
   devolucionesMotivos: devolucionesMotivosReducer,
   devolucionesReportes: devolucionesReportesReducer,
   devolucionesEstatus: devolucionesEstatusReducer,
   devolucionesBancos: devolucionesBancosReducer,
   productosImagenes: productosImagenesReducer,
   servicios: serviciosReducer,
   autogestion: autogestionReducer,
   parametrosRecargos: parametrosRecargosReducer,
   garantias: garantiasReducer,
   garantiasRespuestas: garantiasRespuestasReducer,
   reporteVentas: reporteVentasReducer,
   auth: authReducer,
   productosPropiosProveedor: productosPropiosProveedorReducer,
   historialCambios: historialCambiosReducer,
   productosProveedores: productosProveedoresReducer,
   claves: clavesReducer,
   busquedaClientes: busquedaClientesReducer,
   bodegasPuntoEntrega: bodegasPuntoEntregaReducer,
   eventos: eventosReducer,
   distritos: distritosReducer,
   ciudades: ciudadesReducer,
   usuarios: usuariosReducer,
   puntosEntrega: puntosEntregaReducer,
   modalProductosFiltros: modalProductosFiltrosReducer,
   productosOfertas: productosOfertasReducer,
   productosMetodosPago: productosMetodosPagoReducer,
   productosAdicionales: productosAdicionalesReducer,
   productosPuntosEntrega: productosPuntosEntregaReducer,
   productos: productosReducer,
   etiquetas: etiquetasReducer,
   pedidos: pedidosReducer,
   adicionales: adicionalesReducer,
   metodosPago: metodosPagoReducer,
   ofertas: ofertasReducer,
   atributosValoresRelaciones: atributosValoresRelacionesReducer,
   agendamientos: agendamientosReducer,
   notasVentas: notasVentasReducer,
   agendamientosPuntoEntrega: agendamientosPuntoEntregaReducer,
   ordenesCompra: ordenesCompraReducer,
   toaster: toasterReducer,
   paises: paisesReducer,
   regiones: regionesReducer,
   comunas: comunasReducer,
   tiposElementos: tiposElementosReducer,
   categorias: categoriasReducer,
   cintaInformativa: cintaInformativaReducer,
   faqCategorias: faqCategoriasReducer,
   faqSubcategorias: faqSubcategoriasReducer,
   faq: faqReducer,
   pasosCompra: pasosCompraReducer,
   componentes: componentesReducer,
   banners: bannersReducer,
   menus: menusReducer,
   factoresDiferenciadores: factoresDiferenciadoresReducer,
   terminosCondiciones: terminosCondicionesReducer,
   unidades: unidadesReducer,
   proveedores: proveedoresReducer,
   clavesAtributos: clavesAtributoReducer,
   valoresAtributos: valoresAtributoReducer,
   divisas: divisasReducer,
   tiposDestino: tiposDestinoReducer,
   dias: diasReducer,
   codigosDescuento: codigosDescuentoReducer,
   criteriosComparacion: criteriosComparacionReducer,
   tiposFacturacion: tiposFacturacionReducer,
   estatusPedido: estatusPedidoReducer,
   listedResults: listedResultsReducer,
   metadatos: metadatosReducer,
   metodosEntrega: metodosEntregaReducer,
   roles: rolesReducer,
   productosEtiquetas: productosEtiquetasReducer,
   cotizaciones: cotizacionesReducer,
   generarCotizacion: generarCotizacionReducer, //Revisar referencias
   historialBusquedaCliente: historialBusquedaClienteReducer,
   generarPedido: generarPedidoReducer, //Revisar referencias
   tiposAtencion: tiposAtencionReducer,
   formasPago: formasPagoReducer,
   empresas: empresasReducer,
   reportePedidosCotizaciones: reportePedidosCotizacionesReducer
});

export const store = createStore(reducers, composedEnhancers);
